import React from "react";
import {Route, Switch} from "react-router-dom";
import AdminDashboard from "../../containers/yotta/dashboard/dashboard";
import AdminDashboardOld from "../../containers/yotta/dashboard/dashboard_old";

const Dashboard = ({match}) => (
    <Switch>
        <Route path={`${match.url}/dashboard`} component={AdminDashboard}/>
        {/*<Route path={`${match.url}/dashboard`} component={AdminDashboardOld}/>*/}
    </Switch>
);

export default Dashboard;
