import React, {useState, useEffect} from "react";
import {Form, Row, Col, Input, Button, Collapse, Popconfirm, Card, Table, Popover} from 'antd';
import jsonfile from './voucher.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../util/CISUI';
import AdvancedSearch from '../../../util/AdvancedSearch';
import ActionButton from '../../../util/actionbutton';
import ActionButton2 from '../../../util/ActionButton2';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../util/config";
import {isSet} from "lodash/lang";
import AdvancedSearchBeneficiary from "../../../util/AdvanceSearchWithBeneficiary";
import axios from "../../../util/Api";
import {PrintOutlined} from "@material-ui/icons";

const Search = Input.Search;
const Panel = Collapse.Panel;

const AllVoucherList = () => {
    const value = 0;
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle : '',
    });

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver+jsonfile.urls.list
            }
        );
    }, []);

    const history = useHistory();

    const getPrintAction = (row) => {
        if(row.is_multy === 0) {
            return <Link to={"../"+jsonfile.urls.mview+"/"+row.voucher_no}><span style={{fontWeigh: 'bold'}}>{row.voucher_no}</span></Link>
        }
        else {
            return <Link to={"../"+jsonfile.urls.mview+"/"+row.voucher_no}><span style={{fontWeigh: 'bold'}}>{row.voucher_no}</span></Link>
        }
    }

    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        id: {
            invisible : true
        },
        bank_account: {
            invisible : true
        },
        currency: {
            invisible : true
        },
        benificiary_type: {
            invisible : true
        },
        benificiary_id: {
            invisible : true
        },
        is_multy : {
          invisible: true
        },
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        source: {
            text: <IntlMessages id="Beneficiary"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                value
            )
        },
        type: {
            text: <IntlMessages id="Payment Method"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                value
            )
        },
        voucher_no: {
            text: <IntlMessages id="Voucher No#"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                getPrintAction(row)
            )
        },
        head: {
            text: <IntlMessages id="Account"/>,
        },
        debit: {
            text: <IntlMessages id="Receipt"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        credit: {
            text: <IntlMessages id="Payment"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        amount_fc: {
            text: <IntlMessages id="Foreign Currency"/>,
            sortable: true,
            invisible: true,
            filterable: true,
            transform: (value, idx, row) => (
                row.currency+" "+CisUI().getNumber(row.amount_fc)
            )
        },
        company: {
            text: <IntlMessages id="Company"/>,
            sortable: true,
            filterable: true,
            invisible: true
        },
        user_name: {
            text: <IntlMessages id="Created by"/>,
            sortable: true,
            filterable: true,
        },
        title: {
            text: <IntlMessages id="Title"/>,
            sortable: true,
            filterable: true,
            invisible: true
        },
        reference: {
            text: <IntlMessages id="Reference"/>,
            sortable: true,
            filterable: true,
        },
        attachment: {
            text: <IntlMessages id="Attachment"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                <>
                    {
                        (value === "" || value === NaN || value === null) ? <></> :
                            <Popover placement="leftBottom" title={"Attachment"} content={<>
                                <p>
                                    <a onClick={()=>FileOpen(value)}>
                                        <button className="btn btn-primary btn-circle">
                                            <i className="fas fa-folder-open"/>
                                        </button>
                                        <span style={{paddingLeft: '5px'}}><IntlMessages id="View" /></span>
                                    </a>
                                    {/*<a href={Config.attachement+""+value} target={"_blank"} className="btn btn-primary btn-sm btn-circle" ><i className="fa fa-folder-open"></i> <span style={{color: '#072f4c', fontSize: '15px', paddingLeft: '10px'}}>View</span></a>*/}
                                </p>
                                <p>
                                    <Popconfirm
                                        placement="topRight"
                                        title={"Are you sure you want to delete this attachment ?"}
                                        onConfirm={() => confirmRemove(value)}
                                        okText="Yes"
                                        cancelText="No"
                                    >

                                        <a href="#">
                                            <button className="btn btn-danger btn-circle" >
                                            <i className="fas fa-trash"/>
                                                </button>
                                            <IntlMessages id="Delete" />
                                        </a>
                                    </Popconfirm>
                                </p>
                            </>} trigger="click">
                                <button className="btn btn-primary btn-circle"><i className="fas fa-file"></i></button>
                                {/*<a href={Config.attachement+""+value} target={"_blank"} className="btn btn-primary btn-sm btn-circle" ><i className="fa fa-file"></i></a>*/}
                            </Popover>
                    }
                </>
            ),
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {CisUI().getTransactionStatus(value)}
                </>
            ),
        },
        checque_status: {
            text: <IntlMessages id="Check Status"/>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    <Popconfirm
                        placement="topRight"
                        title={value === 1 ? "Are you sure you want to unclear this voucher ?" : "Are you sure you want to clear this voucher ?"}
                        onConfirm={() => checkClearUnclear(row.id)}
                        okText="Yes"
                        cancelText="No"
                    >

                        <a href="#">
                            {CisUI().getChecqueStatus(value)}
                        </a>
                    </Popconfirm>
                </>
            ),
        },
        actions: {
            text: 'Actions',
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {
                        row.is_multy === 1 ?
                            row.status !==3 ? ActionButton2(row.id,jsonfile.urls2,jsonfile.urls.list) : "" :
                        row.status !==3 ? ActionButton2(row.id,jsonfile.urls,jsonfile.urls.list) : ""
                    }
                    <a href={"/voucherprintv1.html?id="+row.voucher_no} target="_blank" className={"btn btn-primary btn-circle"}><i className="fas fa-print" /></a>
                </>
            ),
        },
    };

    const FileOpen = (fileID) => {
        return window.open(Config.attachement + fileID, "_blank").focus();
    }

    const confirmRemove = () => {

    }

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qsup = CisUI().getQueryStrings(values);
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        setState({reqURL: newURL});
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearchBeneficiary(jsonfile.input,"",1,"date",CisUI().PeriodFiled(form),form)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add,"",state.reqURL,"data",'all_transaction', jsonfile, state.subtitle, "landscape")}
        </>
    );

    const checkClearUnclear = (id) => {
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver+"entries/clearcheck/"+id,null)
            .then(res => {
                // setLoading({loading: false});
                // document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    CisUI().showAlert("Success","success",res.data.msg);
                    setState({reqURL: state.reqURL+"?ts="+Math.random()});
                } else {
                    CisUI().showAlert("Alert","warning",res.data.msg);
                }
                document.getElementById("loader").style.display = "none";
                // setLoading({loading: false});
            })
            .catch(errors => {
                // console.log(errors.response.data.errors);
                // setLoading({loading: false});
                document.getElementById("loader").style.display = "none";
            });
    }

    return (
        <>
            {CisUI().showLoading}
            {searchView}
            <Card title={jsonfile.listtitle} extra={getExtra}>
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default AllVoucherList;