import React from "react";
import ReactDOM from "react-dom";

import NextApp from './NextApp';
import * as serviceWorker from './registerServiceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import axios from 'util/Api'
// import {INIT_URL} from "./constants/ActionTypes";

// axios.defaults.baseURL = 'https://api.penterp.com/api/';
axios.defaults.baseURL = 'https://sandboxapi.penterp.com/api/';

ReactDOM.render(<NextApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
