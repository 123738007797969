import {Col, Card, notification, Row, Form, Collapse} from "antd";
import Widget from "components/Widget/index";
import PropTypes from "prop-types";
import jsonfile from "./dashboard.json"
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Metrics from "../../../components/Metrics";
import {
    BarChart, Bar, Legend,
    Area,
    AreaChart,
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis

} from "recharts";
import Portfolio from "../../../components/dashboard/Crypto/Portfolio";
import Auxiliary from "util/Auxiliary";
import ChartCard from "../../../components/dashboard/Crypto/ChartCard";
import axios from "../../../util/Api";
import {CisUI} from "../../../util/CISUI";
import Config from "../../../util/config";
import IntlMessages from "../../../util/IntlMessages";
import CanvasJSReact from "./../../canvasjs/canvasjs.react";
import "./newdashboard.css";
import "./dashboard.css";
import DateBasedLine from "../../../routes/extensions/charts/amchart/line/Components/DateBasedLine";
import FillBetweenTwoLines from "../../../routes/extensions/charts/amchart/line/Components/FillBetweenTwoLines";
import CustomActiveShapePieChart
    from "../../../routes/extensions/charts/recharts/pie/Components/CustomActiveShapePieChart";
import CustomActiveShapePieChart2
    from "../../../routes/extensions/charts/recharts/pie/Components/CustomActiveShapePieChart";

import  localStorage  from  "react-secure-storage";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const Panel = Collapse.Panel;

const AdminDashboard = (props) => {
    const userLocal = localStorage.getItem("userData") || "";
    let userID = 0;
    let userName = "";
    let user = {
        id: 0,
        name: "",
    };
    if (userLocal !== "") {
        user = JSON.parse(userLocal);
        userID = user["id"];
        userName = user["name"];
    }

    const [incomeExpense, setInocmeExpnese] = useState([]);
    const [incomesSummary, setIncomesSummary] = useState(
        {
            grossTithes: {
                currentMonth : 0,
                lastMonth: 0,
                lastSixMonth: []
            },
            dExpense: {
                currentMonth : 0,
                lastMonth: 0,
                lastSixMonth: []
            },
            netTithe: {
                currentMonth : 0,
                lastMonth: 0,
                lastSixMonth: []
            },
            missions: {
                currentMonth : 0,
                lastMonth: 0,
                lastSixMonth: []
            },
            incomes : {
                data : [],
                currentMonth : 0,
                lastMonth: 0
            },
            expenses : {
                data : [],
                currentMonth : 0,
                lastMonth: 0
            },
            purchase : {
                data : [],
                currentMonth : 0,
                lastMonth: 0
            },
            sales : {
                data : [],
                currentMonth : 0,
                lastMonth: 0
            },
            ministry : []
        }
    );
    const [state, setState] = useState({
        totalMember: 0,
        totalPayingMember: 0,
        totalNonPayingMember: 0,
        payingPercent: 0,
        nonPayingPercent: 0,
        totalTithe: 0,
        titheCompare: [],
        currentMonthCollection: {
            tithe: 0,
            mission: 0,
            others: 0,
            total: 0,
        },
        previousMonthCollection: {
            tithe: 0,
            mission: 0,
            others: 0,
            total: 0,
        },
        thisyearDonation: [],
        grossTithe: 0,
        allowableExpense: 0,
        netTithe: 0,
        devFund: 0,
        devFundTitle: "",
        kpi: [],
        bankReconcile: [],
        highestTithe: {
            name: "",
            amount: 0,
        },
        highestMission: {
            name: "",
            amount: 0,
        },
        titheByRegion: [],
        incomeByRestriction: [],
        cashBalance: 0,
        exenseYT: 0,
        receiveableBalance: 0,
        payableBalance: 0,
        notification: {
            notification: 0,
            bill: 0,
            po: 0,
            task: 0,
        },
        entryMenu: [],
        voucherIncomeVsPayament: [],
        PurchaseAndSales: [],
        cashOnHand: 0,
        getTotalExpenses: 0,
        getBudgetByDepartment: [],
        getTitheReport: [],
        getVouchers: [],
        allarea: [],
        topperformer: {
            title : "",
            areaName: "",
            data: []
        },
        voucherStatus: {receipt: 0, payment: 0, rp: 0, pp: 0}
    });



    useEffect(() => {
        getincomeslast6month();
        getDashboardData();
    }, []);


    const getincomeslast6month = () => {
        // document.getElementById("loader").style.display = "block";
        axios
            .get(Config.apiserver + "dashboard/getincomeslast6month", null, CisUI().HeaderRequest)
            .then((res) => {
                // console.log(res);
                if (res.data.status === 1) {
                    setIncomesSummary({
                        grossTithes: res.data.grossTithes,
                        dExpense: res.data.dExpense,
                        netTithe: res.data.netTithe,
                        missions: res.data.missions,
                        incomes: res.data.incomes,
                        expenses: res.data.expenses,
                        purchase: res.data.purchase,
                        sales: res.data.sales,
                        ministry: res.data.ministry,
                    })
                }
                // document.getElementById("loader").style.display = "none";
            })
            .catch((errors) => {
                // console.log(errors.response.data.errors);
                console.log(errors);
                //ocument.getElementById("loader").style.display = "none";
            });
    };

    const getDashboardData = () => {
        // document.getElementById("loader").style.display = "block";
        axios
            .get(Config.apiserver + "dashboard", null, CisUI().HeaderRequest)
            .then((res) => {
                // console.log(res);
                if (res.data.status === 1) {
                    setInocmeExpnese(res.data.incomeExpense);
                    const totalMember = res.data.totalMember;
                    const totalPayingMember = res.data.totalPayingMember;
                    const totalNonPayingMember = res.data.totalNonPayingMember;
                    const payingPercent = (
                        (totalPayingMember * 100) /
                        totalMember
                    ).toFixed(2);
                    const nonPayingPercent = (
                        (totalNonPayingMember * 100) /
                        totalMember
                    ).toFixed(2);

                    setState({
                        totalMember: totalMember,
                        totalPayingMember: totalPayingMember || 0,
                        payingPercent: payingPercent || 0,
                        totalNonPayingMember: totalNonPayingMember || 0,
                        nonPayingPercent: nonPayingPercent || 0,
                        totalTithe: res.data.totalTithe,
                        titheCompare: res.data.titheCompare,
                        currentMonthCollection: res.data.currentMonthCollection,
                        previousMonthCollection: res.data.previousMonthCollection,
                        thisyearDonation: res.data.thisyearDonation,
                        grossTithe: res.data.grossTithe,
                        allowableExpense: res.data.allowableExpense,
                        netTithe: res.data.netTithe,
                        devFund: res.data.devFund,
                        devFundTitle: res.data.devFundTitle,
                        kpi: res.data.kpi,
                        bankReconcile: res.data.bankReconcile,
                        highestTithe: res.data.highestTithe,
                        highestMission: res.data.highestMission,
                        titheByRegion: res.data.titheByRegion,
                        incomeByRestriction: res.data.incomeByRestriction,
                        exenseYT: res.data.exenseYT,
                        cashBalance: res.data.cashBalance,
                        receiveableBalance: res.data.receiveableBalance,
                        payableBalance: res.data.payableBalance,
                        notification: res.data.notification,
                        entryMenu: res.data.entryMenu,
                        voucherIncomeVsPayament: res.data.voucherIncomeVsPayament,
                        PurchaseAndSales: res.data.PurchaseAndSales,
                        cashOnHand: res.data.cashOnHand,
                        getTotalExpenses: res.data.getTotalExpenses,
                        getBudgetByDepartment: res.data.getBudgetByDepartment,
                        getTitheReport: res.data.getTitheReport,
                        getVouchers: res.data.getVouchers,
                        allarea: res.data.area,
                        topperformer: res.data.topPerformer,
                        voucherStatus: res.data.voucherStatus,
                    });
                } else {
                    notification.warning({
                        message: "Alert",
                        type: "warning",
                        description: res.data.msg,
                    });
                }
                // document.getElementById("loader").style.display = "none";
            })
            .catch((errors) => {
                // console.log(errors.response.data.errors);
                console.log(errors);
                //ocument.getElementById("loader").style.display = "none";
            });
    };

    CanvasJS.addColorSet("brandedset", [
        "#2F4F4F",
        "#008080",
        "#2E8B57",
        "#3CB371",
        "#90EE90",
    ]);

    CanvasJS.addColorSet("brandColorPlates", [
        "#142850",
        "#27496d",
        "#0c7b93",
        "#00a8cc",
        "#8566aa",
        "#6983aa",
        "#8ec6c5",
        "#4d4c7d",
        "#2c003e",
        "#7f78d2",
        "#1eb2a6",
    ]);

    const colorPlates = [
        "#142850",
        "#27496d",
        "#0c7b93",
        "#00a8cc",
        "#8566aa",
        "#6983aa",
        "#8ec6c5",
        "#4d4c7d",
        "#2c003e",
        "#7f78d2",
        "#1eb2a6",
    ];

    // const colors = scaleOrdinal(schemeCategory10).range();

    const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
        x + width / 2
    }, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${
        y + height
    } ${x + width}, ${y + height}
          Z`;

    const TriangleBar = (props) => {
        const {fill, x, y, width, height} = props;

        return <path d={getPath(x, y, width, height)} stroke="none" fill={fill}/>;
    };

    TriangleBar.propTypes = {
        fill: PropTypes.string,
        x: PropTypes.number,
        y: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
    };

    const titheByRegion = {
        animationEnabled: true,
        colorset: colorPlates,
        axisX: {
            title: "Area",
        },
        axisY: {
            title: "Amount",
        },
        toolTip: {
            shared: true,
        },
        legend: {
            cursor: "pointer",
        },
        labelLine: false,
        theme: "light2", //"light1", "dark1", "dark2"
        data: [
            {
                type: "column",
                name: "Tithe",
                showInLegend: false,
                color: "#00A8CCFF",
                yValueFormatString: "₵ #,##0.#",
                dataPoints: state.titheByRegion,
            },
        ],
    };

    const QuickRatio = {
        animationEnabled: true,
        title: {
            text: "Quick Ratio",
        },
        subtitles: [
            {
                text: "1.10% Positive",
                verticalAlign: "center",
                fontSize: 24,
                dockInsidePlotArea: true,
            },
        ],
        data: [
            {
                type: "doughnut",
                showInLegend: true,
                indexLabel: "{name}: {y}",
                yValueFormatString: "#,###'%'",
                colorSet: "brandedset",
                dataPoints: [
                    {name: "Cash Equivalent", y: 5},
                    {name: "Marketable Security", y: 30},
                    {name: "Accounts receivable", y: 25},
                    {name: "Current Liabilities", y: 40},
                ],
            },
        ],
    };

    const IncomeByRestriction = {
        animationEnabled: true,
        data: [
            {
                type: "doughnut",
                showInLegend: true,
                indexLabel: "{name}: {y}",
                yValueFormatString: "₵#,###",
                colorSet: "brandedset",
                dataPoints: state.incomeByRestriction,
            },
        ],
    };

    const width = 400;

    const data = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];


    const TreeMapdata = [
        {
            name: "axis",
            children: [
                {name: "Axes", size: 1302},
                {name: "Axis", size: 24593},
                {name: "AxisGridLine", size: 652},
                {name: "AxisLabel", size: 636},
                {name: "CartesianAxes", size: 6703}
            ]
        },
        {
            name: "controls",
            children: [
                {name: "AnchorControl", size: 2138},
                {name: "ClickControl", size: 3824},
                {name: "Control", size: 1353},
                {name: "ControlList", size: 4665},
                {name: "DragControl", size: 2649},
                {name: "ExpandControl", size: 2832},
                {name: "HoverControl", size: 4896},
                {name: "IControl", size: 763},
                {name: "PanZoomControl", size: 5222},
                {name: "SelectionControl", size: 7862},
                {name: "TooltipControl", size: 8435}
            ]
        },
        {
            name: "data",
            children: [
                {name: "Data", size: 20544},
                {name: "DataList", size: 19788},
                {name: "DataSprite", size: 10349},
                {name: "EdgeSprite", size: 3301},
                {name: "NodeSprite", size: 19382},
                {
                    name: "render",
                    children: [
                        {name: "ArrowType", size: 698},
                        {name: "EdgeRenderer", size: 5569},
                        {name: "IRenderer", size: 353},
                        {name: "ShapeRenderer", size: 2247}
                    ]
                },
                {name: "ScaleBinding", size: 11275},
                {name: "Tree", size: 7147},
                {name: "TreeBuilder", size: 9930}
            ]
        },
        {
            name: "events",
            children: [
                {name: "DataEvent", size: 7313},
                {name: "SelectionEvent", size: 6880},
                {name: "TooltipEvent", size: 3701},
                {name: "VisualizationEvent", size: 2117}
            ]
        },
        {
            name: "legend",
            children: [
                {name: "Legend", size: 20859},
                {name: "LegendItem", size: 4614},
                {name: "LegendRange", size: 10530}
            ]
        },
        {
            name: "operator",
            children: [
                {
                    name: "distortion",
                    children: [
                        {name: "BifocalDistortion", size: 4461},
                        {name: "Distortion", size: 6314},
                        {name: "FisheyeDistortion", size: 3444}
                    ]
                },
                {
                    name: "encoder",
                    children: [
                        {name: "ColorEncoder", size: 3179},
                        {name: "Encoder", size: 4060},
                        {name: "PropertyEncoder", size: 4138},
                        {name: "ShapeEncoder", size: 1690},
                        {name: "SizeEncoder", size: 1830}
                    ]
                },
                {
                    name: "filter",
                    children: [
                        {name: "FisheyeTreeFilter", size: 5219},
                        {name: "GraphDistanceFilter", size: 3165},
                        {name: "VisibilityFilter", size: 3509}
                    ]
                },
                {name: "IOperator", size: 1286},
                {
                    name: "label",
                    children: [
                        {name: "Labeler", size: 9956},
                        {name: "RadialLabeler", size: 3899},
                        {name: "StackedAreaLabeler", size: 3202}
                    ]
                },
                {
                    name: "layout",
                    children: [
                        {name: "AxisLayout", size: 6725},
                        {name: "BundledEdgeRouter", size: 3727},
                        {name: "CircleLayout", size: 9317},
                        {name: "CirclePackingLayout", size: 12003},
                        {name: "DendrogramLayout", size: 4853},
                        {name: "ForceDirectedLayout", size: 8411},
                        {name: "IcicleTreeLayout", size: 4864},
                        {name: "IndentedTreeLayout", size: 3174},
                        {name: "Layout", size: 7881},
                        {name: "NodeLinkTreeLayout", size: 12870},
                        {name: "PieLayout", size: 2728},
                        {name: "RadialTreeLayout", size: 12348},
                        {name: "RandomLayout", size: 870},
                        {name: "StackedAreaLayout", size: 9121},
                        {name: "TreeMapLayout", size: 9191}
                    ]
                },
                {name: "Operator", size: 2490},
                {name: "OperatorList", size: 5248},
                {name: "OperatorSequence", size: 4190},
                {name: "OperatorSwitch", size: 2581},
                {name: "SortOperator", size: 2023}
            ]
        }
    ];


    const CustomizedContent = (props) => {
        const {root, depth, x, y, width, height, index, colors, name, value} = props;
        // console.log(value);

        return (
            <g>
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    style={{
                        fill:
                            depth < 2
                                ? colors[Math.floor((index / root.children.length) * 6)]
                                : "none",
                        stroke: "#fff",
                        strokeWidth: 2 / (depth + 1e-10),
                        strokeOpacity: 1 / (depth + 1e-10)
                    }}
                />
                {depth === 1 ? (
                    <text
                        x={x + width / 2}
                        y={y + height / 2 + 7}
                        textAnchor="middle"
                        fill="#fff"
                        fontSize={14}
                    >
                        {name}
                    </text>
                ) : null}
                {depth === 1 ? (
                    <text x={x + 4} y={y + 18} fill="#fff" fontSize={16} fillOpacity={0.9}>
                        {index + 1}
                    </text>
                ) : null}
            </g>
        );
    };


    const piedata = [
        {name: 'Group A', value: 400},
        {name: 'Group B', value: 300},
        {name: 'Group C', value: 300},
        {name: 'Group D', value: 200},
    ];

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const departmentExpenses = {
        animationEnabled: true,
        theme: "light2",
        colorSet: "brandColorPlates",
        title: {
            text: ""
        },
        axisX: {
            title: state.topperformer.areaName,
            reversed: false,
        },
        axisY: {
            title: "Previous Month Returns",
            includeZero: true,
            valueFormatString: "₵##,##,##,##0.##",
            // labelFormatString: "₵##,##,##,###.##",
            // labelFormatter: "₵##,##,##,###.##",
        },
        data: [{
            type: "bar",
            dataPoints: state.allarea
        }]
    }

    const comArea = CisUI().getUserInfo('comarea');

    const titheTitle = () => {
        if(comArea <= 3){
            return "From Area";
        }
        else if(comArea === 4){
            return "From District";
        }
        else if(comArea === 5){
            return "From Local";
        }
        else {
            return ""
        }
    }

    return (
        <Auxiliary>
            {CisUI().showLoading}
            <Row>
                <Col span={24} style={{display: 'none'}}>
                    <Card>
                        <Collapse
                            accordion={false}
                            expandIconPosition="right"
                            defaultActiveKey={['0']} >
                            <Panel header={<IntlMessages id={"Favourites"}/>} key="1">
                                <div className="row">
                                    {
                                        state.entryMenu.map((items, index) => {
                                            return <div className="col-md-2">
                                                <p className="gx-fs-sm gx-text-uppercase">
                                                    <strong>{items.name}</strong>
                                                </p>
                                                <ul className="gx-list-group" style={{paddingLeft: '10px'}}>
                                                    {
                                                        items.submodule.map((sub, indx) => {
                                                            return <>
                                                                {
                                                                    sub.menu.map((mnu, mi)=>{
                                                                        return <li>
                                                                                    <span>
                                                                                        <Link to={"../"+items.urlkey+"/" + mnu.urlkey}><i
                                                                                            className="fa fa-dot-circle"></i> {mnu.name}</Link>
                                                                                    </span>
                                                                        </li>
                                                                    })
                                                                }
                                                            </>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        })
                                    }
                                </div>
                            </Panel>
                        </Collapse>
                    </Card>
                </Col>

                <Col span={24} >
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <ChartCard
                                        widgetTitle={"Gross Tithe "+titheTitle()}
                                        prize={CisUI().getCurrencyFormated1(incomesSummary.grossTithes.currentMonth)}
                                        title={CisUI().getCurrencyFormated1(incomesSummary.grossTithes.lastMonth)}
                                        icon="bitcoin"
                                        prizeTitle="Current Month"
                                        titleDesc="Last Month"
                                        widgetTitleClass={"dashboardWidgetClass"}
                                        children={
                                            <ResponsiveContainer width="100%" height={75}>
                                                <AreaChart
                                                    data={incomesSummary.grossTithes.lastSixMonth}
                                                    margin={{top: 0, right: 0, left: 0, bottom: 0}}
                                                >
                                                    <XAxis dataKey="name" />
                                                    <Tooltip/>
                                                    <defs>
                                                        <linearGradient
                                                            id="color3"
                                                            x1="0"
                                                            y1="0"
                                                            x2="1"
                                                            y2="0"
                                                        >
                                                            <stop
                                                                offset="25%"
                                                                stopColor="#26648E"
                                                                stopOpacity={0.99}
                                                            />
                                                            <stop
                                                                offset="75%"
                                                                stopColor="#3196e2"
                                                                stopOpacity={0.99}
                                                            />
                                                        </linearGradient>
                                                    </defs>
                                                    <Area
                                                        dataKey="amount"
                                                        strokeWidth={0}
                                                        stackId="2"
                                                        stroke="#020f3e"
                                                        fill="url(#color3)"
                                                        fillOpacity={1}
                                                    />
                                                </AreaChart>
                                            </ResponsiveContainer>
                                        }
                                        styleName="up"
                                        desc="Last 6 Month Tithe Summary"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <ChartCard
                                        widgetTitle={"Expenses ("+titheTitle()+")"}
                                        prize={CisUI().getCurrencyFormated1(incomesSummary.dExpense.currentMonth)}
                                        title={CisUI().getCurrencyFormated1(incomesSummary.dExpense.lastMonth)}
                                        icon="bitcoin"
                                        prizeTitle="Current Month"
                                        titleDesc="Last Month"
                                        widgetTitleClass={"dashboardWidgetClass"}
                                        children={
                                            <ResponsiveContainer width="100%" height={75}>
                                                <AreaChart
                                                    data={incomesSummary.dExpense.lastSixMonth}
                                                    margin={{top: 0, right: 0, left: 0, bottom: 0}}
                                                >
                                                    <XAxis dataKey="name" />
                                                    <Tooltip/>
                                                    <defs>
                                                        <linearGradient
                                                            id="color4"
                                                            x1="0"
                                                            y1="0"
                                                            x2="1"
                                                            y2="0"
                                                        >
                                                            <stop
                                                                offset="5%"
                                                                stopColor="#FFC05F"
                                                                stopOpacity={0.9}
                                                            />
                                                            <stop
                                                                offset="95%"
                                                                stopColor="#1d3668"
                                                                stopOpacity={0.9}
                                                            />
                                                        </linearGradient>
                                                    </defs>
                                                    <Area
                                                        dataKey="amount"
                                                        type="monotone"
                                                        strokeWidth={0}
                                                        stackId="2"
                                                        stroke="#FFC05F"
                                                        fill="url(#color4)"
                                                        fillOpacity={1}
                                                    />
                                                </AreaChart>
                                            </ResponsiveContainer>
                                        }
                                        styleName="up"
                                        desc="Last 6 Month Expenses"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <ChartCard
                                        widgetTitle={"Net Tithe"}
                                        prize={CisUI().getCurrencyFormated1(incomesSummary.netTithe.currentMonth)}
                                        title={CisUI().getCurrencyFormated1(incomesSummary.netTithe.lastMonth)}
                                        icon="bitcoin"
                                        prizeTitle="Current Month"
                                        titleDesc="Last Month"
                                        widgetTitleClass={"dashboardWidgetClass"}
                                        children={
                                            <ResponsiveContainer width="100%" height={75}>
                                                <AreaChart
                                                    data={incomesSummary.netTithe.lastSixMonth}
                                                    margin={{top: 0, right: 0, left: 0, bottom: 0}}
                                                >
                                                    <XAxis dataKey="name" />
                                                    <Tooltip/>
                                                    <defs>
                                                        <linearGradient
                                                            id="color5"
                                                            x1="0"
                                                            y1="0"
                                                            x2="0"
                                                            y2="1"
                                                        >
                                                            <stop
                                                                offset="5%"
                                                                stopColor="#90ADC6"
                                                                stopOpacity={0.8}
                                                            />
                                                            <stop
                                                                offset="95%"
                                                                stopColor="#333652"
                                                                stopOpacity={0.8}
                                                            />
                                                        </linearGradient>
                                                    </defs>
                                                    <Area
                                                        dataKey="amount"
                                                        strokeWidth={0}
                                                        stackId="2"
                                                        stroke="#13c2c2"
                                                        fill="url(#color5)"
                                                        fillOpacity={1}
                                                    />
                                                </AreaChart>
                                            </ResponsiveContainer>
                                        }
                                        styleName="down"
                                        desc="Last 6 Month Purchase Summary"
                                    />
                                </div>

                                <div className="col-md-6">
                                    <ChartCard
                                        widgetTitle={"Missions Offering"}
                                        prize={CisUI().getCurrencyFormated1(incomesSummary.missions.currentMonth)}
                                        title={CisUI().getCurrencyFormated1(incomesSummary.missions.lastMonth)}
                                        icon="bitcoin"
                                        prizeTitle="Current Month"
                                        titleDesc="Last Month"
                                        widgetTitleClass={"dashboardWidgetClass"}
                                        children={
                                            <ResponsiveContainer width="100%" height={75}>
                                                <LineChart
                                                    data={incomesSummary.missions.lastSixMonth}
                                                    margin={{top: 5, right: 5, left: 5, bottom: 5}}
                                                >
                                                    <XAxis dataKey="name" />
                                                    <Tooltip/>
                                                    <Line
                                                        dataKey="amount"
                                                        stroke="#003060"
                                                        dot={{stroke: "#13c2c2", strokeWidth: 2}}
                                                    />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        }
                                        styleName="down"
                                        desc="Last 6 Month Summary"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="s_card">
                                <div className="s_card_title">{state.topperformer.title}</div>
                                {/*<TopArea datas={incomesSummary.ministry} />*/}
                                {/*{PieChart3D(incomesSummary.ministry)}*/}
                                {/*<TopAreaLine />*/}
                                <div className="App" style={{minHeight: '480px'}}>
                                    <CanvasJSChart options={departmentExpenses} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={24} >
                    <div className="row">
                        <div className="col-md-5">
                            <Portfolio data={state.voucherStatus} />
                        </div>

                        <div className="col-md-7" style={{overflow: 'auto'}}>
                            <Widget styleName={`gx-card-full gx-bg-color tithestatus`}>
                                <h3 style={{paddingBottom: '20px'}}>Current & Last month Tithe Summary</h3>
                                <div className="gx-customers">
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th><IntlMessages id={"Title"}/></th>
                                            <th className="currency"><IntlMessages id={"Previous Month"}/></th>
                                            <th className="currency"><IntlMessages id={"Current Month"}/></th>
                                            <th className="currency"><IntlMessages id={"Differences"}/></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Net Tithe and Offering</td>
                                            <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.tithe)}</td>
                                            <td className="currency">{CisUI().getCurrencyFormated1(state.currentMonthCollection.tithe)}</td>
                                            <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.tithe - state.currentMonthCollection.tithe)}</td>
                                        </tr>
                                        <tr>
                                            <td>Add: Missions Offering</td>
                                            <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.mission)}</td>
                                            <td className="currency">{CisUI().getCurrencyFormated1(state.currentMonthCollection.mission)}</td>
                                            <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.mission - state.currentMonthCollection.mission)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.total)}</td>
                                            <td className="currency">{CisUI().getCurrencyFormated1(state.currentMonthCollection.total)}</td>
                                            <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.total - state.currentMonthCollection.total)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Widget>
                        </div>

                        {/*<div className="col-md-5">*/}
                        {/*    <BalanceHistory/>*/}
                        {/*</div>*/}

                        {/*<div className="col-md-2">*/}
                        {/*    <div className="s_card">*/}
                        {/*        <Column3DChart/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
                </Col>


            </Row>

            <div className="row">
                <div className="col-md-4">

                    {/*<div className="gx-card gx-card-border-red">*/}
                    {/*    <div className="gx-card-body">*/}
                    {/*        <div className="gx-wel-ema gx-pt-xl-2">*/}
                    {/*            <h4>HR Update</h4>*/}
                    {/*            <ResponsiveContainer width="95%" height={400}>*/}
                    {/*                <Treemap*/}
                    {/*                    data={TreeMapdata}*/}
                    {/*                    dataKey="size"*/}
                    {/*                    stroke="#fff"*/}
                    {/*                    fill="#8884d8"*/}
                    {/*                    content={<CustomizedContent colors={colorPlates}/>}*/}
                    {/*                >*/}
                    {/*                    <Tooltip/>*/}
                    {/*                </Treemap>*/}
                    {/*            </ResponsiveContainer>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <div className="col-md-12" style={{overflow: 'auto'}}>
                        <Metrics title={<IntlMessages id={"Total Gross Tithes - Current Year"}/>}>
                            <Row>
                                <Col xl={11} lg={12} md={24} sm={12} xs={12}>
                                    <h4 className="gx-mb-1">{CisUI().getCurrencyFormated1(state.totalTithe)}</h4>
                                    <p className="gx-mb-md-0 gx-text-light"><IntlMessages
                                        id={"Total Tithes YTD"}/></p>
                                </Col>
                                <Col xl={13} lg={12} md={24} sm={12} xs={12}>

                                    <ResponsiveContainer className="gx-barchart" width="100%" height={70}>
                                        <BarChart data={state.titheCompare}
                                                  margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                            <CartesianGrid strokeDasharray="3 3"/>
                                            <XAxis dataKey="name"/>
                                            <Tooltip
                                                formatter={(value) => new Intl.NumberFormat('en').format(value)}/>
                                            <Bar dataKey="lastYear" stackId="a" fill="#27496d"
                                                 barSize={width <= 575 ? 4 : 8}/>
                                            <Bar dataKey="thisYear" stackId="a" fill="#FE9E15"
                                                 barSize={width <= 575 ? 4 : 8}/>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Col>
                            </Row>
                        </Metrics>
                    </div>

                    <div className="col-md-12" style={{overflow: 'auto'}}>
                        <Metrics styleName={`gx-card-full`} title="Last Month Donation by Type">
                            <Row>
                                <Col lg={24} md={24} sm={15} xs={15}>
                                    <ResponsiveContainer width="100%" height={103}>
                                        <AreaChart data={state.thisyearDonation}
                                                   margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                            <defs>
                                                <linearGradient id="color1" x1="0" y1="0" x2="1" y2="0">
                                                    <stop offset="5%" stopColor="#27496d" stopOpacity={0.9}/>
                                                    <stop offset="95%" stopColor="#00a8cc" stopOpacity={0.9}/>
                                                </linearGradient>
                                            </defs>
                                            <XAxis dataKey="name"/>
                                            <YAxis tickFormatter={(value) => new Intl.NumberFormat('en', {
                                                notation: "compact",
                                                compactDisplay: "short"
                                            }).format(value)}/>
                                            <CartesianGrid strokeDasharray="3 3"/>
                                            <Tooltip
                                                formatter={(value) => CisUI().getCurrencyFormated1(value)}/>
                                            <Area type="monotone" dataKey='total' strokeWidth={0} stackId="2"
                                                  stroke='#867AE5' fill="url(#color1)" fillOpacity={1}/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </Col>
                            </Row>
                        </Metrics>
                    </div>

                    <div className="col-md-12">
                        <Widget styleName={`gx-card-full gx-p-3 gx-bg-color`}>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-2 gx-mr-xxl-3">
                                    <i className={`icon icon-tasks gx-fs-icon-lg`}/>
                                </div>
                                <div className="gx-media-body">
                                    <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1">
                                        {CisUI().getCurrencyFormated1(state.cashOnHand)}
                                    </h4>
                                    <p className="gx-mb-0">CASH EQU. BALANCE</p>
                                </div>
                            </div>
                        </Widget>
                    </div>
                    <div className="col-md-12">
                        <Widget styleName={`gx-card-full gx-p-3 gx-bg-color`}>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-2 gx-mr-xxl-3">
                                    <i className={`icon icon-tasks gx-fs-icon-lg`}/>
                                </div>
                                <div className="gx-media-body">
                                    <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1">
                                        {CisUI().getCurrencyFormated1(state.getTotalExpenses)}
                                    </h4>
                                    <p className="gx-mb-0">TOTAL EXPENSES</p>
                                </div>
                            </div>
                        </Widget>
                    </div>

                    <div className="col-md-12">

                        <div className="row">
                            <div className="col-md-6">
                                <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap"
                                         style={{height: '60px'}}>
                                        <div className="gx-mr-2 gx-mr-xxl-3">
                                            <i className={`icon icon-diamond gx-fs-icon-lg`}
                                               style={{color: '#27496d'}}/>
                                        </div>
                                        <div className="gx-media-body">
                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{
                                                fontSize: '18px',
                                                color: '#00a8cc'
                                            }}>{CisUI().getCurrencyFormated1(state.grossTithe)}</h4>
                                            <p className="gx-mb-0" style={{color: '#27496d'}}>
                                                {
                                                    CisUI().getUserInfo('comarea') <= 3 ? "Tithe Collected" : CisUI().getUserInfo('comarea') === 4 ? "Gross Tithe from District" : CisUI().getUserInfo('comarea') === 5 ? "Gross Tithe From Local" : CisUI().getUserInfo('comarea') === 6 ? "Gross Tithe" : ""
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </Widget>
                            </div>
                            <div className="col-md-6">
                                <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap"
                                         style={{height: '60px'}}>
                                        <div className="gx-mr-2 gx-mr-xxl-3">
                                            <i className={`icon icon-tasks gx-fs-icon-lg`}
                                               style={{color: '#27496d'}}/>
                                        </div>
                                        <div className="gx-media-body">
                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{
                                                fontSize: '18px',
                                                color: '#00a8cc'
                                            }}>{CisUI().getCurrencyFormated1(state.allowableExpense)}</h4>
                                            <p className="gx-mb-0" style={{color: '#27496d'}}>Allowable
                                                Expenses</p>
                                        </div>
                                    </div>
                                </Widget>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="col-md-8">

                    <div className="row">
                        <div className="col-md-8">
                            <div className="gx-card gx-card-border-gold">
                                <div className="gx-card-body">
                                    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
                                        <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">
                                            <IntlMessages id={"Income & Expenditure"}/>
                                        </h6>
                                        <ResponsiveContainer width="100%" height={340}>
                                            <AreaChart data={incomeExpense}>
                                                <defs>
                                                    <linearGradient
                                                        id="colorIncome"
                                                        x1="0"
                                                        y1="0"
                                                        x2="0"
                                                        y2="1"
                                                    >
                                                        <stop
                                                            offset="5%"
                                                            stopColor="#27496d"
                                                            stopOpacity={0.8}
                                                        />
                                                        <stopgetDashboardData
                                                            offset="95%"
                                                            stopColor="#00a8cc"
                                                            stopOpacity={0.4}
                                                        />
                                                    </linearGradient>
                                                    <linearGradient
                                                        id="colorExpnese"
                                                        x1="0"
                                                        y1="0"
                                                        x2="0"
                                                        y2="1"
                                                    >
                                                        <stop
                                                            offset="5%"
                                                            stopColor="#bf163f"
                                                            stopOpacity={0.8}
                                                        />
                                                        <stop
                                                            offset="95%"
                                                            stopColor="#bf163f"
                                                            stopOpacity={0.4}
                                                        />
                                                    </linearGradient>
                                                </defs>
                                                <XAxis dataKey="name"/>
                                                <YAxis
                                                    tickFormatter={(value) =>
                                                        new Intl.NumberFormat("en", {
                                                            notation: "compact",
                                                            compactDisplay: "short",
                                                        }).format(value)
                                                    }
                                                />
                                                <CartesianGrid strokeDasharray="3 3"/>
                                                <Tooltip
                                                    formatter={(value) =>
                                                        new Intl.NumberFormat("en").format(value)
                                                    }
                                                />
                                                <Area
                                                    type="monotone"
                                                    dataKey="Income"
                                                    label={"Income"}
                                                    stroke="#8884d8"
                                                    fillOpacity={1}
                                                    fill="url(#colorIncome)"
                                                />
                                                <Area
                                                    type="monotone"
                                                    dataKey="Expense"
                                                    label={"Expense"}
                                                    stroke="#82ca9d"
                                                    fillOpacity={1}
                                                    fill="url(#colorExpnese)"
                                                />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">

                            <Row>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                        <div className="gx-media gx-align-items-center gx-flex-nowrap"
                                             style={{height: '40px'}}>
                                            <div className="gx-mr-2 gx-mr-xxl-3">
                                                <i className={`icon icon-diamond gx-fs-icon-lg`}
                                                   style={{color: '#27496d'}}/>
                                            </div>
                                            <div className="gx-media-body">
                                                <h3 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{
                                                    fontSize: '18px',
                                                    color: '#00a8cc'
                                                }}>{CisUI().getCurrencyFormated1(state.grossTithe)}</h3>
                                                <p className="gx-mb-0" style={{color: '#27496d'}}>
                                                    Total Revenue <br/>(This year)
                                                </p>
                                            </div>
                                        </div>
                                    </Widget>
                                </Col>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                        <div className="gx-media gx-align-items-center gx-flex-nowrap"
                                             style={{height: '40px'}}>
                                            <div className="gx-mr-2 gx-mr-xxl-3">
                                                <i className={`icon icon-tasks gx-fs-icon-lg`}
                                                   style={{color: '#27496d'}}/>
                                            </div>
                                            <div className="gx-media-body">
                                                <h3 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{
                                                    fontSize: '18px',
                                                    color: '#00a8cc'
                                                }}>{CisUI().getCurrencyFormated1(state.allowableExpense)}</h3>
                                                <p className="gx-mb-0" style={{color: '#27496d'}}>Total
                                                    Expenses <br/> (This Year)</p>
                                            </div>
                                        </div>
                                    </Widget>
                                </Col>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                        <div className="gx-media gx-align-items-center gx-flex-nowrap"
                                             style={{height: '40px'}}>
                                            <div className="gx-mr-2 gx-mr-xxl-3">
                                                <i className={`icon icon-team gx-fs-icon-lg`}
                                                   style={{color: '#27496d'}}/>
                                            </div>
                                            <div className="gx-media-body">
                                                <h3 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1 " style={{
                                                    fontSize: '18px',
                                                    color: '#00a8cc'
                                                }}>{CisUI().getCurrencyFormated1(state.netTithe)}</h3>
                                                <p className="gx-mb-0" style={{color: '#27496d'}}>
                                                    Cash in Hand
                                                </p>
                                            </div>
                                        </div>
                                    </Widget>
                                </Col>
                            </Row>

                        </div>


                    </div>


                    <div className="row">
                        <div className="col-md-12" style={{display: "none"}}>
                            <div className="s_card">
                                <div className="s_card_title">
                                    Current Year Budget by Department
                                </div>
                                {/*<TwoSimplePieChart/>*/}
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart
                                        data={state.getBudgetByDepartment}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="name"/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Legend/>
                                        <Bar dataKey="income" label="Income" fill="#1F618D"/>
                                        <Bar dataKey="expense" label="Expense" fill="#3DA0E2"/>
                                    </BarChart>
                                </ResponsiveContainer>

                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="s_card" style={{height: '325px'}}>
                                <div className="s_card_title">
                                    Tithes and Expenses
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            {
                                                jsonfile.listView.hq_title.map((items, index) => {
                                                    return <th>{items}</th>
                                                })
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            state.getTitheReport.map((items, index) => {
                                                if (items.fl_type === 'total') {
                                                    return <tr>
                                                        <th>{items["month"]}</th>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.gross_tithe)}</th>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.ldf)}</th>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.ddf)}</th>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.adf)}</th>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.local_expense)}</th>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.district_expense)}</th>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.area_expense)}</th>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.sal_allowance)}</th>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.medicals)}</th>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.total_deduction)}</th>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.balance_deposited)}</th>
                                                    </tr>
                                                } else {
                                                    return <tr>
                                                        <td>{items["month"]}</td>
                                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.gross_tithe)}</td>
                                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.ldf)}</td>
                                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.ddf)}</td>
                                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.adf)}</td>
                                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.local_expense)}</td>
                                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.district_expense)}</td>
                                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.area_expense)}</td>
                                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.sal_allowance)}</td>
                                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.medicals)}</td>
                                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.total_deduction)}</td>
                                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.balance_deposited)}</td>
                                                    </tr>
                                                }
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col-md-4">
                    <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                        <div className="gx-media gx-align-items-center gx-flex-nowrap"
                             style={{height: '60px'}}>
                            <div className="gx-mr-2 gx-mr-xxl-3">
                                <i className={`icon icon-team gx-fs-icon-lg`}
                                   style={{color: '#27496d'}}/>
                            </div>
                            <div className="gx-media-body">
                                <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1 " style={{
                                    fontSize: '18px',
                                    color: '#00a8cc'
                                }}>{CisUI().getCurrencyFormated1(state.netTithe)}</h4>
                                <p className="gx-mb-0" style={{color: '#27496d'}}>
                                    {
                                        CisUI().getUserInfo('comarea') <= 3 ? "Net Tithe From Area" : CisUI().getUserInfo('comarea') === 4 ? "Net to Nation" : CisUI().getUserInfo('comarea') === 5 ? "Net to Area" : CisUI().getUserInfo('comarea') === 6 ? "Net to District" : ""
                                    }
                                </p>
                            </div>
                        </div>
                    </Widget>
                </div>


                <div className="col-md-4">
                    <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                        <div className="gx-media gx-align-items-center gx-flex-nowrap"
                             style={{height: '60px'}}>
                            <div className="gx-media-body">
                                <h4 className="gx-fs-xl text-center gx-font-weight-semi-bold gx-mb-1"
                                    style={{
                                        fontSize: '18px',
                                        color: '#00a8cc'
                                    }}>{CisUI().getCurrencyFormated1(state.highestTithe.amount)}</h4>
                                <p className="gx-mb-0 text-center" style={{color: '#27496d'}}>{
                                    CisUI().getUserInfo('comarea') === 6 ? "Net Income " : "Highest Net Tithe "
                                }
                                    {/*{state.highestTithe.name}*/}
                                </p>
                            </div>
                        </div>
                    </Widget>
                </div>

                <div className="col-md-4">
                    <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                        <div className="gx-media gx-align-items-center gx-flex-nowrap"
                             style={{height: '60px'}}>
                            <div className="gx-media-body">
                                <p>Highest Missions Offering</p>
                                <h4 className="gx-fs-xl text-center gx-font-weight-semi-bold gx-mb-1"
                                    style={{
                                        fontSize: '18px',
                                        color: '#00a8cc'
                                    }}>{CisUI().getCurrencyFormated1(state.highestMission.amount)}</h4>
                                <p className="text-center gx-mb-0"
                                   style={{color: '#27496d'}}>{state.highestMission.name}</p>
                            </div>
                        </div>
                    </Widget>
                </div>


            </div>

            <div className="row">

                <div className="col-md-12">
                    <div className="s_card">
                        <div className="s_card_title">Last 10 Vouchers</div>
                        <div className="s_card_body">
                            <table className="table table-hover invoice_table">
                                <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Voucher#</th>
                                    <th scope="col">Account</th>
                                    <th scope="col">Doc Class</th>
                                    <th scope="col">Receipt</th>
                                    <th scope="col">Payment</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    state.getVouchers.map((items, index) => {
                                        return <tr>
                                            <td>{CisUI().DateFormat(items.date)}</td>
                                            <td>{items.voucher_no}</td>
                                            <td>{items.title}</td>
                                            <td>{items.doc_class_name}</td>
                                            <td>{CisUI().getCurrencyFormated1(items.debit)}</td>
                                            <td>{CisUI().getCurrencyFormated1(items.credit)}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
                <div className="col-md-12" style={{display: 'none'}}>
                    <Widget title="Current Financial Status">
                        <div className="row">
                            <div className="col-md-3">
                                <Metrics styleName={`gx-card-full gx-bg-white`}>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-media-body">
                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1"
                                                style={{
                                                    fontSize: '18px',
                                                    color: '#00a8cc'
                                                }}>{CisUI().getCurrencyFormated1(state.cashBalance)}</h4>
                                            <p className="gx-mb-0" style={{color: '#27496d'}}>Cash Equ.
                                                Balance</p>
                                        </div>
                                    </div>
                                </Metrics>
                            </div>
                            <div className="col-md-3">
                                <Metrics styleName={`gx-card-full gx-bg-white`}>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-media-body">
                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1"
                                                style={{
                                                    fontSize: '18px',
                                                    color: '#00a8cc'
                                                }}>{CisUI().getCurrencyFormated1(state.exenseYT)}</h4>
                                            <p className="gx-mb-0" style={{color: '#27496d'}}>Total
                                                Expenses</p>
                                        </div>
                                    </div>
                                </Metrics>
                            </div>
                            <div className="col-md-3">
                                <Metrics styleName={`gx-card-full gx-bg-white`}>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-media-body">
                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1"
                                                style={{
                                                    fontSize: '18px',
                                                    color: '#00a8cc'
                                                }}>{CisUI().getCurrencyFormated1(state.receiveableBalance)}</h4>
                                            <p className="gx-mb-0" style={{color: '#27496d'}}>Act.
                                                Receivable</p>
                                        </div>
                                    </div>
                                </Metrics>
                            </div>
                            <div className="col-md-3">
                                <Metrics styleName={`gx-card-full gx-bg-white`}>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-media-body">
                                            <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1"
                                                style={{
                                                    fontSize: '18px',
                                                    color: '#00a8cc'
                                                }}>{CisUI().getCurrencyFormated1(state.payableBalance)}</h4>
                                            <p className="gx-mb-0" style={{color: '#27496d'}}>Act.
                                                Payable</p>
                                        </div>
                                    </div>
                                </Metrics>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th><IntlMessages id={"Indicator"}/></th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>
                                            <IntlMessages id={"Current Month"}/></th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>
                                            <IntlMessages id={"Previous Month"}/></th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>
                                            <IntlMessages id={"Change"}/></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        state.kpi.map((items, index) => {
                                            return <tr>
                                                <td>{items.name}</td>
                                                <td style={{textAlign: 'right', paddingRight: '10px'}}>
                                                    {
                                                        items.current > items.last ?
                                                            <i style={{color: 'green'}}
                                                               className="fas fa-caret-up"/> : items.change < items.last ?
                                                                <i style={{color: 'red'}}
                                                                   className="fas fa-caret-down"/> : ""
                                                    }
                                                    {items.current}</td>
                                                <td style={{
                                                    textAlign: 'right',
                                                    paddingRight: '10px'
                                                }}>{items.last}</td>
                                                <td style={{textAlign: 'right', paddingRight: '10px'}}>
                                                    {
                                                        items.change > 0 ? <i style={{color: 'green'}}
                                                                              className="fas fa-caret-up"/> : items.change < 0 ?
                                                            <i style={{color: 'red'}}
                                                               className="fas fa-caret-down"/> : ""
                                                    }
                                                    {items.change}
                                                </td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Widget>
                </div>

                <Col span={24} style={{display: "none"}} >
                    <div className="row">
                        <div className="col-md-5">
                            <div className="s_card">
                                <div className="s_card_title">Current Month Sales</div>
                                <div className="s_card_body">
                                    <FillBetweenTwoLines/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="s_card">
                                        <div className="s_card_title">Current Month Voucher Created</div>
                                        <div className="s_card_body">
                                            <DateBasedLine/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="s_card">
                                        <div className="s_card_title">Sales by Ministry</div>

                                        <CustomActiveShapePieChart/>

                                        <hr/>
                                        <div className="s_card_title">Sales by Ministry</div>
                                        <CustomActiveShapePieChart2/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{display: "none"}}>
                    <Widget title="Bank Reconciliation Summary">
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Account</th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>Account Balance</th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>Last Reconcile Balance
                                        </th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>Last Reconcile Date</th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>Bank Balance</th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>Balance as of</th>
                                        <th style={{textAlign: 'right', paddingRight: '10px'}}>Items to Match</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        state.bankReconcile.map((items, index) => {
                                            return <tr>
                                                <td>{items.name}</td>
                                                <td style={{
                                                    textAlign: 'right',
                                                    paddingRight: '10px'
                                                }}>{CisUI().getCurrencyFormated1(items.balance)}</td>
                                                <td style={{
                                                    textAlign: 'right',
                                                    paddingRight: '10px'
                                                }}>{CisUI().getCurrencyFormated1(items.lastBalance)}</td>
                                                <td style={{
                                                    textAlign: 'right',
                                                    paddingRight: '10px'
                                                }}>{items.lastDate !== "" ? CisUI().DateFormat(items.lastDate) : ""}</td>
                                                <td style={{
                                                    textAlign: 'right',
                                                    paddingRight: '10px'
                                                }}>{CisUI().getCurrencyFormated1(items.bankBalance)}</td>
                                                <td style={{
                                                    textAlign: 'right',
                                                    paddingRight: '10px'
                                                }}>{items.balanceDate !== '' ? CisUI().DateFormat(items.balanceDate) : ""}</td>
                                                <td style={{
                                                    textAlign: 'right',
                                                    paddingRight: '10px'
                                                }}>{CisUI().getCurrencyFormated1(items.itemToMatch)}</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Widget>
                </Col>


            </div>

        </Auxiliary>
    );
};

export default AdminDashboard;
