import {Form, Row, Col, Radio, Input, Button, Card, Collapse, Modal, Select, DatePicker, Checkbox, notification, Spin} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './voucher.json'
import {CisUI} from '../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import RemoteJson from "../../../util/gerremotedata";
import SelectData from "../../../util/selectdata";
import moment from "moment";
import 'moment/locale/es-us';

const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');


const VoucherDetails = (props) => {

    const formRef = React.createRef();
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();
    const history = useHistory();

    const [company, setCompany] = useState({});
    const [state, setState] = useState({});
    const [voucherType, setvoucherType] = useState("");

    const poid = props.match.params.id;
    const isEdit = 1;

    useEffect(() => {
        getPODetails();
    }, []);

    const getPODetails = () => {
        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver +jsonfile.urls.view+"/"+ poid)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                //console.log(res.data);
                if (res.data.status === 1) {
                    setState(res.data.data);
                    setCompany(res.data.company);
                    if(res.data.data["debit"] > 0) {
                        setvoucherType("Receipt Voucher");
                    }
                    else {
                        setvoucherType("Payment Voucher");
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }


    let totalPaid = 0;
    const onPrintClick = () => {
        window.print();
    }

    return (
        <Card title={"Voucher Details"} extra= {
            <>
                <Button id="submitbuttons" type="primary" htmlType="button">
                    <Link to={"../voucheredit/"+poid}>Edit</Link>
                </Button>
                {
                    isEdit === 1
                        ? CisUI().addAction('../../' + jsonfile.urls.list)
                        : CisUI().addAction('../' + jsonfile.urls.list)
                }
            </>
        }>
            {CisUI().showLoading}
            <div className="row">
                <div className="col-md-12" style={{textAlign: "right"}}>
                    <a className="ant-btn ant-btn-primary no-print" onClick={onPrintClick}>
                        <i className="fa fa-print"></i> Print
                    </a>
                </div>
            </div>
            <div className="receipt-area">
                <div className="receipt-content">
                    <div className="invoice_header">
                        <div className="voucher-details">
                            <h1>{voucherType}</h1>
                            <div className="details">
                                <div className="title">
                                    <p>Voucher number</p>
                                    <p>Voucher Date</p>
                                    <p>Account</p>
                                </div>
                                <div>
                                    <p>: {state.voucher_no}</p>
                                    <p>: {CisUI().DateFormat(state.date)}</p>
                                    <p>: {state.title}</p>
                                </div>
                            </div>
                        </div>
                        <div className="logo" style={{textAlign : 'center'}}>
                            <img src="/assets/images/poc_logo.png" alt="POC" />
                            <h3>The Church of Pentecost</h3>
                            <h3>{company.name}</h3>
                        </div>
                    </div>
                    <div>
                        <table className="product-list">
                            <thead>
                            <tr>
                                <th>Particulars</th>
                                <th style={{width: '150px', textAlign : 'right'}}>Receipt</th>
                                <th style={{width: '150px', textAlign : 'right'}}>Payment</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{state.title}<br/>Document Class : {state.doc_class_name}-{state.doc_class_desc}</td>
                                <td style={{width: '150px', textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.debit || 0)}</td>
                                <td style={{width: '150px', textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.credit || 0)}</td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td></td>
                                <td className="table-footer">{CisUI().getCurrencyFormated1(state.debit || 0)}</td>
                                <td className="table-footer">{CisUI().getCurrencyFormated1(state.credit || 0)}</td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className="invoice_footer">
                        <p style={{textAlign : 'center'}}>
                            {company.legal_name}
                            {/*<br/>{company.address_line_1+","+company.address_line_2+","+company.city+","+company.state+","+company.country}<br />*/}
                            {/*Phone # : {company.phone1}<br/>*/}
                            {/*Email : {company.email}<br/>*/}
                            {/*Website : {Config.website}<br/>*/}
                        </p>
                    </div>
                </div>
            </div>



        </Card>
    );
};

export default VoucherDetails;